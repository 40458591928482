
class LoginTS {
    private readonly loginContentDiv = $('#loginContent');
    private readonly forgotPasswordLink = $('#btnForgotPassword');
    private readonly loginForm = $('#loginForm');
    private readonly passwordRecoveryForm = $('#passwordRecoveryForm');

    constructor() {
        let self = this;
        this.forgotPasswordLink.on('click', () => this.startPasswordRecovery())
    }

    private startPasswordRecovery(): any {
        this.loginForm.hide()
        this.passwordRecoveryForm.show()
    }

}

window.onload = () => {
    new LoginTS()
}